import React from 'react';

import SvgCommonReusableElements from '@components/svgJsx/svgCommonReusableElements.js';
import ToastNotification_ContextProvider from '@providers/ToastNotification';
import NetworkCheck_ContextProvider from '@providers/NetworkAvailabilityCheck';

import ToastNotifier from '@components/ToastNotifier';

//this component will be used in gatsby-browser.js and gatsby-ssr.js to wrap the gatsby root element
//in this we can provide all Context Providers

export default function AppRoot({ children }) {
	return (
		<>
			<SvgCommonReusableElements />
			<ToastNotification_ContextProvider>
				<NetworkCheck_ContextProvider>{children}</NetworkCheck_ContextProvider>
			</ToastNotification_ContextProvider>
		</>
	);
}

export function AppRootUI({ children }) {
	return (
		<>
			{children}
			<ToastNotifier />
		</>
	);
}
