import React, { useContext } from 'react';
import clsx from 'clsx';
import { ToastNotificationContext } from '@providers/ToastNotification';

import * as styles from './ToastNotifier.module.css';

/**
 * Displays a Toast Notification UI message.
 * Imported in Root
 */
export default function ToastNotifier() {
	const { isViz, notification, type, closeToastNotification } = useContext(ToastNotificationContext);
	return (
		<div
			className={clsx(
				styles.notice,
				(type === 'ok' || type === 'success') && styles.ok,
				type === 'err' && styles.err,
				type === 'warn' && styles.warn,
				type === 'alert' && styles.alert,
				isViz && styles.viz,
				typeof notification == 'object' && styles.custom,
			)}
			onClick={closeToastNotification}
		>
			{notification}
		</div>
	);
}
